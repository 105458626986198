import {
  MaxWidthContainer,
  Row,
  Spacer,
  media,
  HurleyThemeProps,
} from "@hurleymc/components"

import * as React from "react"
import styled, { css } from "styled-components"

const StyledFooter = styled.footer`
  background-color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.mercury};
  display: block;
  width: 100%;
`

const LogoBlock = styled.div`
  display: flex;
  justify-content: row;
  flex-wrap: nowrap;
  margin-bottom: 2rem;
`

const LogoImage = styled.img`
  height: 4rem;

  ${media.breakpoint.up(
    "md",
    css`
      height: 5rem;
    `
  )}

  ${media.breakpoint.up(
    "lg",
    css`
      height: 6rem;
    `
  )}
`

const LogoDivider = styled.div`
  background-color: ${({ theme }: HurleyThemeProps) =>
    theme.colors.named.silver};
  display: block;
  height: 4rem;
  margin: 0 1rem;
  width: 1px;

  ${media.breakpoint.up(
    "md",
    css`
      height: 5rem;
    `
  )}

  ${media.breakpoint.up(
    "lg",
    css`
      height: 6rem;
    `
  )}
`

const AddressBlock = styled.a`
  display: block;

  strong {
    color: #333;
    font-weight: bold;
  }

  address {
    color: #666;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 0.5rem;
  }
`

const PhoneNumber = styled.div`
  margin-bottom: 2rem;
`

const InsideReport = styled.div`
  margin-bottom: 2rem;
`

const SocialBar = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 2rem;

  img {
    display: block;
    width: 3rem;
  }
`

const InfoBlock = styled.div`
  color: #666;
  font-size: 0.7rem;
`

const googleMapsUrl =
  "https://www.google.com/maps/place/Hurley+Foundation/@43.0217956,-83.7053418,15z/data=!4m2!3m1!1s0x0:0x3f486c636a871ebe?sa=X&ved=2ahUKEwjKhvrw8svcAhWhy4MKHY_eB5IQ_BIwEXoECAgQCw"

const Footer = ({ className = null, ...props }) => {
  return (
    <MaxWidthContainer className={className} {...props}>
      <Row>
        <StyledFooter>
          <Spacer margin="4rem 1rem">
            <LogoBlock>
              <a href="/">
                <LogoImage
                  src="https://s3.amazonaws.com/images.files.edit.hurleymc.com/Logos/hurley-foundation.png"
                  alt=""
                />
              </a>
              <LogoDivider />
              <a
                href="https://hurley.childrensmiraclenetworkhospitals.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LogoImage
                  src="https://s3.amazonaws.com/images.files.edit.hurleymc.com/Logos/cmn-hospitals.png"
                  alt=""
                />
              </a>
            </LogoBlock>

            <AddressBlock
              href={googleMapsUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <address>
                <strong>Hurley Foundation</strong>
                <br />
                One Hurley Plaza
                <br />
                Flint, MI 48503
              </address>
            </AddressBlock>

            <PhoneNumber>
              <a href="tel:+1-810-262-9667">(810) 262-9667</a>
            </PhoneNumber>

            <InsideReport>
              <strong>
                {/* this page needs to be loaded directly from the server */}
                <a href="/inside-report">Inside Report</a>
              </strong>
            </InsideReport>

            <SocialBar>
              <a
                href="https://twitter.com/HurleyMedical/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://s3.amazonaws.com/images.files.edit.hurleymc.com/Logos/Social/twitter.png"
                  alt="Twitter Icon"
                />
              </a>
              <a
                href="https://www.instagram.com/hurleymedicalcenter/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://s3.amazonaws.com/images.files.edit.hurleymc.com/Logos/Social/instagram.png"
                  alt="Instagram Icon"
                />
              </a>
              <a
                href="https://www.youtube.com/user/HurleyMedicalCenter1/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://s3.amazonaws.com/images.files.edit.hurleymc.com/Logos/Social/youtube.png"
                  alt="YouTube Icon"
                />
              </a>
              <a
                href="https://www.facebook.com/HurleyMedicalCenter/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://s3.amazonaws.com/images.files.edit.hurleymc.com/Logos/Social/facebook.png"
                  alt="Facebook Icon"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/hurley-medical-center/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://s3.amazonaws.com/images.files.edit.hurleymc.com/Logos/Social/linkedin.png"
                  alt="LinkedIn Icon"
                />
              </a>
            </SocialBar>

            <InfoBlock>
              Hurley Foundation is a 501 (c)(3) | ein: 38-3085047
            </InfoBlock>
          </Spacer>
        </StyledFooter>
      </Row>
    </MaxWidthContainer>
  )
}

export default Footer
